import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import "./scripts";
import {
  faBars,
  faCircle,
  faPumpSoap,
  faSoap,
} from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-bootstrap";

let carouselImages = new Array(10).fill(undefined);
carouselImages = carouselImages.map(
  (_, i) => "/assets/img/cars/" + (i + 2) + ".png"
);

const IndexPage = () => {
  const [navClass, setNavClass] = React.useState("");

  React.useEffect(() => {
    if (window) {
      window.onscroll = () => {
        setNavClass(window.scrollY !== 0 ? " navbar-shrink" : "");
      };
    }
  });
  return (
    <>
      <nav
        className={"navbar navbar-expand-lg navbar-dark fixed-top" + navClass}
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand" href="#page-top">
            <img
              src="assets/img/navbar-logo.jpg"
              style={{ height: "3rem" }}
              alt="..."
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <FontAwesomeIcon icon={faBars} className="ms-1" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#my-work">
                  My Work
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/Morgan-Detailing-103563404513466/"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/*<!-- Masthead-->*/}
      <header className="masthead">
        <div className="container">
          <h1 className="masthead-heading text-uppercase mb-2">
            Car Detailing
          </h1>
          <h2 className="masthead-subheading mb-5">
            Serving the greater Sioux City area
          </h2>
          <a className="btn btn-primary btn-xl text-uppercase" href="#my-work">
            My Work
          </a>
        </div>
      </header>
      {/*<!-- Services-->*/}
      <section className="page-section" id="services">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">Services</h2>
          </div>
          <div className="row text-center">
            <div className="col-md-6 px-5">
              <span className="fa-stack fa-4x">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-primary fa-stack-2x"
                />
                <FontAwesomeIcon
                  className="fa-stack-1x"
                  icon={faSoap}
                  inverse={true}
                />
              </span>
              <h4 className="my-3">Interior Cleaning</h4>
              <p className="text-muted">
                Includes vacuuming, shampooing, stain extracting, dog hair
                removal, leather and vinyl treat, and window cleaning.
              </p>
            </div>
            <div className="col-md-6 px-5">
              <span className="fa-stack fa-4x">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-primary fa-stack-2x"
                />
                <FontAwesomeIcon
                  className="fa-stack-1x"
                  icon={faPumpSoap}
                  inverse={true}
                />
              </span>
              <h4 className="my-3">Exterior Cleaning</h4>

              <p className="text-muted">
                Clay barring, scratch removal, and buffing.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="image-carousel page-section" id="my-work">
        <div className="text-center mb-4">
          <h2 className="section-heading text-uppercase">Check Out My Work</h2>
        </div>
        <Carousel className="px-3 w-75 mx-auto" style={{ maxWidth: 800 }}>
          {carouselImages.map((image, i) => (
            <Carousel.Item>
              <img className="d-block w-100" src={image} alt={`slide ${i}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
      {/*<!-- About -->*/}
      <section className="page-section bg-light" id="about">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">About Me</h2>
            <h3 className="section-subheading text-muted">
              Blair Morgan grew up in Kingsley, Iowa. He has been detailing
              since the age of 16 and has a passion for vehicles.
            </h3>
          </div>
          <div>
            <div className="text-center">
              <div className="team-member">
                <img
                  className="mx-auto rounded-circle"
                  src="assets/img/team/blair.png"
                  alt="..."
                />
                <h4>Blair Morgan</h4>
                <p className="text-muted">Sole Proprietor</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <p className="large blockquote">
                "I strive to make every car look as good as if it were my own!
                There is no better feeling than seeing a clean vehicle cruising
                down the road!"
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- Contact-->*/}
      <section className="page-section" id="contact">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">Contact</h2>
            <div className="section-subheading text-white p-3">
              Let's talk to find out what I can do for you!
            </div>
            <div className="section-subheading text-white">
              <a className="text-decoration-none" href="tel:712-870-4873">
                712-870-4873
              </a>
            </div>
            <div className="section-subheading text-white">
              <a
                className="text-decoration-none"
                href="mailto:blair@morgandetailing.com"
              >
                blair@morgandetailing.com
              </a>
            </div>
            <div className="section-subheading text-white">
              <span>405 E 1st Street, Kingsley, Iowa, 51028</span>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- Footer-->*/}
      <footer className="footer py-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-lg-start">
              &copy; Morgan Detailing LLC {new Date().getFullYear()}
            </div>
            <div className="col-lg-6 text-lg-end">
              <a
                className="link-dark text-decoration-none me-3"
                href="https://demerstech.com"
              >
                Created by Patrick Demers.
              </a>
            </div>
          </div>
        </div>
      </footer>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
      <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
    </>
  );
};

export default IndexPage;
